<template>
  <div>
    <div class="video-bg">
      <div v-if="$device.isDesktop" class="video-overlay-container">
        <div class="video-overlay-text">
          <span class="video-overlay-text-title">{{ t('welcomeScreenTextTitle') }}</span>
          <span class="video-overlay-text-description" v-html="t('welcomeScreenTextDescription')" />
        </div>
        <div class="video-overlay-buttons">
          <NuxtLink to="/marketplace"
            ><button class="btn-primary" size="large">{{ t('welcomeScreenMarketPlaceButton') }}</button></NuxtLink
          >
          <NuxtLink to="/game"
            ><button class="btn-primary" size="large">{{ t('welcomeScreenPlayButton') }}</button></NuxtLink
          >
        </div>
      </div>
      <div v-if="$device.isDesktop" class="video-overlay-gradient" />
      <video ref="refVideo" muted loop playsinline poster="~public/img/video-poster.png" preload="none">
        <source v-if="$device.isDesktop" src="~public/assets/video/desktop-bg.mp4" type="video/mp4" />
        <source v-else src="~public/assets/video/mobile-bg.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useIdle } from '@vueuse/core';
import { useI18n } from '#imports';

const refVideo = ref<HTMLVideoElement>();
const { idle } = useIdle(1000);
const { t } = useI18n();

onMounted(() => {
  setTimeout(() => {
    refVideo.value?.play();
  }, 2000);
});

watch(idle, (idleValue) => {
  if (!idleValue) {
    refVideo.value?.play();
  }
});
</script>

<style scoped>
.video-bg {
  display: flex;
  position: relative;
  height: calc(100vh - 130px);

  @media screen and (max-width: 560px) {
    height: auto;
  }

  video {
    height: 100%;
    width: 100%;
    overflow: hidden;
    object-fit: cover;

    @media screen and (max-width: 560px) {
      object-fit: contain;
    }
  }

  .video-overlay-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(5, 36, 48, 0.44723827030812324) 45%, rgba(17, 68, 88, 1) 100%);
  }

  .video-overlay-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .video-overlay-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;

    @media screen and (max-width: 479px) {
      gap: 10px;
    }

    .btn-primary {
      font-size: 20px;
      white-space: nowrap;
      width: 240px;

      @media screen and (max-width: 479px) {
        width: 200px;
        font-size: 16px;
      }
    }
  }

  .video-overlay-text {
    font-family: Grenze, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    color: #ffffff;

    .video-overlay-text-title {
      text-wrap: nowrap;
      font-size: 64px;
      line-height: 70px;
      font-weight: 700;

      @media screen and (max-width: 1200px) {
        font-size: 35px;
      }

      @media screen and (max-width: 560px) {
        font-size: 25px;
      }
    }

    .video-overlay-text-description {
      text-align: center;
      padding-bottom: 40px;
      padding-top: 30px;
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;

      @media screen and (max-width: 1400px) {
        padding-top: 10px;
        padding-bottom: 20px;
        line-height: 20px;
        font-size: 16px;
      }

      @media screen and (max-width: 767px) {
        white-space: wrap;
      }

      @media screen and (max-width: 560px) {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}
</style>
