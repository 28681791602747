import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/workspace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/workspace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_UYiXMU8ZyN from "/workspace/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_t2GMTTFnMT from "/workspace/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_PSbK9A48ZQ from "/workspace/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/workspace/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/workspace/.nuxt/element-plus-injection.plugin.mjs";
import preload_tokens_images_5kID6SpOeI from "/workspace/plugins/preload-tokens-images.ts";
import _01web3Modal_YHW31RwKJQ from "/workspace/plugins/01web3Modal.ts";
import apexcharts_client_xo3MRJYPBX from "/workspace/plugins/apexcharts.client.js";
import deviceCheckSSR_vYXCXykNdb from "/workspace/plugins/deviceCheckSSR.ts";
import error_handler_kEP5FliEXj from "/workspace/plugins/error-handler.ts";
import helpers_client_Geb2gMzsac from "/workspace/plugins/helpers.client.ts";
import helpers_mgWmzbOuLB from "/workspace/plugins/helpers.ts";
import imageHelpers_client_Had3mptG6c from "/workspace/plugins/imageHelpers.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  plugin_client_UYiXMU8ZyN,
  plugin_t2GMTTFnMT,
  plugin_PSbK9A48ZQ,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  preload_tokens_images_5kID6SpOeI,
  _01web3Modal_YHW31RwKJQ,
  apexcharts_client_xo3MRJYPBX,
  deviceCheckSSR_vYXCXykNdb,
  error_handler_kEP5FliEXj,
  helpers_client_Geb2gMzsac,
  helpers_mgWmzbOuLB,
  imageHelpers_client_Had3mptG6c
]