<template>
  <el-badge :value="badgeValue" class="item">
    <button class="round-btn" size="large" @click.stop="handleClick">
      <img class="" :src="`/img/icons/${props.icon}.svg`" alt="icon" />
    </button>
  </el-badge>
</template>

<script setup lang="ts">
const props = defineProps<{
  icon: string;
  badgeValue?: string | number;
}>();

const emit = defineEmits(['click']);

const handleClick = () => {
  emit('click');
};
</script>

<style lang="scss" scoped>
.round-btn {
  background: linear-gradient(to bottom, #00cc9b, #041bee);
  border-radius: 30px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #016b79;
}
</style>

<style>
.item.el-badge {
  .el-badge__content--danger {
    background-color: #ff6711;
    border: none;
  }

  .round-btn ~ .el-badge__content.is-fixed {
    right: calc(10px + var(--el-badge-size) / 2);
  }
}
</style>
