<template>
  <el-tooltip popper-class="notifications-popper" effect="dark">
    <template #content>
      <div v-if="sortedRecipes?.length" class="available-claim">
        <MobileNotificationItem
          v-for="recipe in sortedRecipes"
          :key="`${recipe?.craftStartTime}`"
          v-model="isLoadingClaimButton"
          :recipe="recipe"
          :icon="'123'"
        />
      </div>
      <div v-else class="no-notifications">{{ $t('coreNotificationsEmpty') }}</div>
    </template>
  </el-tooltip>
</template>

<script setup lang="ts">
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { BigNumber } from 'bignumber.js';
import type { UnclaimedRecipeProps } from '~/types/crafting';

const store = useMainStore();
const { address } = useWeb3ModalAccount();

const { data: recipes } = useNuxtData<UnclaimedRecipeProps[]>('unclaimed-recipes');
const sortedRecipes = ref<UnclaimedRecipeProps[]>([]);

const isLoadingClaimButton = ref(false);
watch(
  () => recipes.value?.length,
  () => {
    sortedRecipes.value =
      recipes.value?.sort((a, b) => Number(new Date(a?.craftStartTime)) - Number(new Date(b.craftStartTime))) || [];
  },
  { immediate: true }
);

watch([() => store.notificationUpdateVersion, address], async ([newUpdateVersion]) => {
  if (!BigNumber(newUpdateVersion).isNaN()) {
    await refreshNuxtData('unclaimed-recipes');
  }
});
</script>

<style lang="scss" scoped>
.available-claim {
  margin-right: 10px;
}
</style>

<style lang="scss">
.notifications-popper {
  z-index: 5;
  background: rgba(0, 0, 0, 0.75) !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  width: 96%;
  padding: 20px 5px 20px 20px;
  color: white;
  text-align: center;
  border-radius: 10px;
  max-height: calc(100vh - 80px - 68px);
  overflow: auto;
  backdrop-filter: blur(4px);
}

.el-popper__arrow {
  display: none;
}

.no-notifications {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
</style>
