<template>
  <div class="fixed-container">
    <div class="mobile-footer" :class="[store.currentDistrict ? 'expanded' : null]">
      <UIRoundButton v-if="!!$route.path.split('/')[1]" icon="home" @click="() => handleButtonClick(null)" />

      <div :class="[store.currentDistrict ? 'full-width-block' : 'left-block']">
        <UIBuildingButton
          icon="mining"
          :text="$t('buildingMiningDistrict')"
          :badge-value="getBadgeValue(Districts.MINING)"
          :mode="store.currentDistrict === Districts.MINING ? 'full' : 'compact'"
          @click.stop="() => handleButtonClick(Districts.MINING)"
        />

        <UIBuildingButton
          icon="produce"
          :text="$t('buildingProductionDistrict')"
          :badge-value="getBadgeValue(Districts.PRODUCTION)"
          :mode="store.currentDistrict === Districts.PRODUCTION ? 'full' : 'compact'"
          @click.stop="() => handleButtonClick(Districts.PRODUCTION)"
        />

        <UIBuildingButton
          icon="city"
          :text="$t('buildingCityDistrict')"
          :badge-value="getBadgeValue(Districts.CITY)"
          :mode="store.currentDistrict === Districts.CITY ? 'full' : 'compact'"
          @click.stop="() => handleButtonClick(Districts.CITY)"
        />
      </div>

      <UIRoundButton
        v-if="!store.currentDistrict && address"
        :badge-value="recipes?.length ? recipes?.length : ''"
        icon="notifications"
        @click="isNotificationsVisible = !isNotificationsVisible"
      />
      <MobileNotifications :recipes="recipes || []" :visible="isNotificationsVisible" />
      <div v-if="isNotificationsVisible" class="notificationsOverlay" @click="isNotificationsVisible = false"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, provide } from 'vue';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { Districts } from '~/types/districts';
import type { UnclaimedRecipeProps } from '~/types/crafting';
import { path } from '~/utils/constants';

const store = useMainStore();
const { address } = useWeb3ModalAccount();
const { apiUrl, blockchain } = useEnvs();
const isNotificationsVisible = ref(false);
const unclaimedQtys = ref(new Map<Districts, number>());

const { data: recipes, refresh: refreshRecipeList } = await useFetch<UnclaimedRecipeProps[]>(
  '/crafting/recipes/unclaimed',
  {
    baseURL: apiUrl,
    query: {
      userAddress: address
    },
    watch: [address, () => store.notificationUpdateVersion],
    key: 'unclaimed-recipes',
    immediate: !!address.value,
    onResponse: ({ response }) => {
      const buildingHashList = blockchain.buildings;
      unclaimedQtys.value.clear();
      response._data.forEach((recipe: UnclaimedRecipeProps) => {
        const { buildingHash } = recipe;
        const recipeDistrict = buildingHashList[buildingHash].district;

        const currentUnclaimedValue = unclaimedQtys.value.get(recipeDistrict) || 0;
        unclaimedQtys.value.set(recipeDistrict, currentUnclaimedValue + recipe.unclaimedModifier);
      });
    }
  }
);

const refreshRecipes = async () => {
  await refreshRecipeList();
};
provide('refreshList', refreshRecipes);

const getBadgeValue = (district: Districts): number | undefined => {
  return unclaimedQtys.value.get(district);
};

const handleButtonClick = (district: Districts | null) => {
  store.currentDistrict = district;
  navigateTo(district ? path.public.districts : path.public.home);
};
</script>

<style lang="scss" scoped>
.button-container {
  position: relative;
  height: 50px;
}

.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
  transform: translateY(0);
}

.fixed-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  z-index: 10;

  @media (max-width: 376px) {
    padding: 10px 5px;
  }
}

.left-block {
  display: flex;
}

.full-width-block {
  display: flex;
  justify-content: left;

  > * {
    margin-left: 15px;
  }
}

.left-block > *:not(:first-child) {
  margin-left: 1em;
}

.mobile-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expanded {
  justify-content: flex-start;
}

.notificationsOverlay {
  position: fixed;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}
</style>
