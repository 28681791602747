<template>
  <div class="scroll-down-info">
    <DoubleArrow
      v-if="isBeginningSwiperInstance || isEndSwiperInstance || isIntermediateSwiperInstance"
      :class="{ lookUp: isBeginningSwiperInstance || isIntermediateSwiperInstance }"
    />
    <p class="info-text">{{ info }}</p>
    <DoubleArrow v-if="!isBeginningSwiperInstance && !isEndSwiperInstance" />
  </div>
</template>

<script setup lang="ts">
import DoubleArrow from '~/components/icons/DoubleArrow.vue';

const props = defineProps<{
  info: string;
  isBeginningSwiperInstance?: boolean;
  isEndSwiperInstance?: boolean;
}>();

const isIntermediateSwiperInstance = computed(() => !props.isBeginningSwiperInstance && !props.isEndSwiperInstance);
</script>

<style scoped lang="scss">
.scroll-down-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: white;
  pointer-events: none;
  opacity: 70%;

  @media (max-width: 376px) {
    transform: scale(0.7);
  }
}

.lookUp {
  transform: rotate(180deg);
}

.info-text {
  text-wrap: nowrap;

  @media (max-width: 479px) {
    font-size: 10px;
    line-height: 1.5;
  }
}
</style>
