import MobileDetect from 'mobile-detect';

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      ssrDevice: () => {
        const userAgent = nuxtApp.ssrContext?.event.headers.get('user-agent');
        const md = new MobileDetect(userAgent || '');
        const isMobile = md.phone() !== null || md.mobile() === 'UnknownMobile';
        const isTablet = md.tablet() !== null || md.mobile() === 'UnknownTablet';
        return { isMobile, isTablet };
      }
    }
  };
});
