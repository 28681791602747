<template>
  <SeasonLeaderboardList
    :style="{ maxHeight: !$device.isMobile ? '50vh' : 'auto', paddingTop: '10px' }"
    :is-jackpot="true"
  />
  <div class="bannerWrapper">
    <img v-if="$device.isMobile" style="width: 100vw" src="/public/img/jackpotMobileBanner.png" alt="" />
    <img v-else style="height: 50vh" src="/public/img/jackpotBanner.png" alt="" />

    <div
      :style="{
        top: locale === 'en' && $device.isMobile ? '10px' : '0'
      }"
      class="topContent"
    >
      <span :style="{ textAlign: locale !== 'en' && $device.isMobile ? 'left' : 'center' }">{{
        $t('winBigPrize')
      }}</span>
      <div
        v-if="$device.isMobile"
        :style="{ textAlign: locale !== 'en' && $device.isMobile ? 'left' : 'center' }"
        class="bottomContent"
      >
        <img src="/img/icons/usdt.svg" alt="" :height="locale === 'ru' ? 15 : 12" />
        <span>{{ BigNumber(jackpotValue).toFormat({ groupSize: 3, groupSeparator: ' ' }) }}</span>
      </div>
    </div>
    <div v-if="$device.isDesktop" class="bottomContent">
      <img src="/img/icons/usdt.svg" alt="" :height="$device.isMobile ? 12 : 20" />
      <span :style="{ fontSize: locale === 'ru' ? '24px' : '40px' }">{{
        BigNumber(jackpotValue).toFormat({ groupSize: 3, groupSeparator: ' ' })
      }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { BigNumber } from 'bignumber.js';

const { locale } = useI18n();
const { jackpotValue } = useEnvs();
</script>
<style lang="scss" scoped>
.bannerWrapper {
  position: relative;

  @media screen and (max-width: 560px) {
    order: -1;
    width: 100%;
  }
  .topContent {
    background-color: rgba($color: #000000, $alpha: 0.5);
    padding: 13px;
    font-size: 28px;
    font-family: Grenze, sans-serif;
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 100%;
    pointer-events: none;
    text-align: center;
    border-radius: 16px;
    line-height: 1;

    > span {
      background: linear-gradient(to top, rgba(38, 255, 203, 1) 0%, rgba(32, 121, 255, 1) 140%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
      text-align: center;
    }

    @media screen and (max-width: 560px) {
      background: none;
      font-size: 20px;
      padding: 0;
      left: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: max-content;
      max-width: 40%;
    }
  }

  .bottomContent {
    background-color: rgba(4, 15, 20, 1);
    background-image: radial-gradient(ellipse 100% 75% at 50% 100%, rgba(210, 86, 69, 1), rgba(0, 0, 0, 0));
    padding: 13px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    text-align: center;
    border-radius: 16px;
    line-height: 1;
    line-height: 1;
    color: rgba(238, 196, 46, 1);
    font-size: 40px;
    font-family: Grenze, sans-serif;

    > span {
      margin-left: 10px;
    }

    @media screen and (max-width: 560px) {
      background: none;
      font-size: 20px;
      padding: 0;
      position: static;
    }
  }
}
</style>
