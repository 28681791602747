<template>
  <div class="container tripleBlockInfo">
    <p class="description" v-html="description" />
    <div class="itemsWrapper">
      <div v-for="(info, i) of config" :key="i" class="flexContainer">
        <UIHighlightedCard>
          <img :width="$device.isMobile ? 90 : 140" :src="info.icon" alt="" />
        </UIHighlightedCard>
        <p class="itemText">{{ $t(info.textKey) }}</p>
      </div>
      <button v-if="$device.isMobile" class="btn-outline small w-button continueBtn" @click="() => emit('close')">
        {{ $t('continue') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { InfoModal } from '~/utils/constants';
const emit = defineEmits(['close']);

defineProps<{
  description: string;
  config: InfoModal[];
}>();
</script>

<style lang="scss">
.description {
  span {
    color: #88e1ff;
  }
}
</style>

<style lang="scss" scoped>
.container {
  width: 100%;
  font-family: Grenze, sans-serif;

  .description {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    width: 100%;
    color: #90989d;
    margin-top: 10px;
  }

  .itemsWrapper {
    margin-top: 25px;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    gap: 50px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
    flex-wrap: wrap;

    @media screen and (max-width: 479px) {
      gap: 20px;
    }
  }

  .flexContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    width: min-content;

    @media screen and (max-width: 767px) {
      flex-direction: row;
      width: 100%;
    }

    @media screen and (max-width: 479px) {
      flex-direction: row;

      p {
        font-size: 18px;
      }

      img {
        width: 98px;
      }
    }
  }

  .itemText {
    font-size: 15px;
    text-align: center;
    color: white;
    font-weight: 500;
    font-family: Grenze, sans-serif;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      text-align: left;
    }
  }

  .continueBtn {
    width: 80%;
    margin-top: 80px;

    @media screen and (max-width: 560px) {
      margin-top: 10px;
    }
  }
}
</style>

<style lang="scss">
.el-dialog.tripleBlockInfo {
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 905px;

  .el-dialog__body {
    max-width: unset;
  }
}
</style>
