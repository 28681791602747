<template>
  <div class="container">
    <p class="description" v-html="description" />
    <div class="btns">
      <button class="btn-outline" @click="() => emit('close')">{{ $t('coreCancel') }}</button>
      <button class="btn-primary" @click="goToLink()">{{ $t('coreProceed') }}</button>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['close']);

defineProps<{
  description: string;
}>();

const goToLink = () => {
  window.open(
    'https://support.metamask.io/managing-my-tokens/custom-tokens/how-to-display-tokens-in-metamask/#how-to-add-a-custom-token',
    '_blank'
  );
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 60vh;
  align-items: center;
  display: flex;
}

.description {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  color: #fff;
  margin-top: 0px;
}

.btns {
  position: fixed;
  bottom: 25px;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;

  button:first-child {
    margin-bottom: 15px;
  }
}
</style>
