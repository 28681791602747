<template>
  <div class="recipeRow">
    <div class="left-block" :class="[recipe.unclaimedModifier > 0 ? 'fullWidth' : null]">
      <vue-countdown
        :time="countdownTimestamp"
        @progress="({ totalMilliseconds }) => trackCountdownProgress(totalMilliseconds)"
      >
      </vue-countdown>

      <div class="recipeRowInfo">
        <img :src="getIcon(recipe.recipeId, recipe.products[0].tokenAddress)" :alt="tokenMetadataComputed?.label" />
        <span class="tokenName">
          {{ $t(tokenMetadataComputed?.label || '') }} (<span>{{
            countdownTimestamp >= 0 ? timerTokensForClaimRef : recipe.unclaimedModifier
          }}</span>
          /
          {{ recipe.multiplyModifier }})
        </span>
      </div>
      <div :class="['recipeRowProgress', percent <= CRAFTING_PERCENT ? 'default' : 'halfDone']">
        <div
          :class="['progressFill', percent <= CRAFTING_PERCENT ? 'default' : 'halfDone']"
          :style="{ width: `${percent > 100 ? 100 : percent}%` }"
        />
      </div>
    </div>
    <button
      v-if="recipe.unclaimedModifier > 0"
      :disabled="isClaimDisabled"
      class="btn-primary"
      type="button"
      @click="claimCraftedTokens()"
    >
      <span>{{ $t('craftingClaim') }} &nbsp; </span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, computed } from 'vue';
import { calculateTimeLeftPercent } from '~/utils/crafting';
import { CRAFTING_PERCENT, ERRORS_CONFIG } from '~/utils/constants';
import type { RecipeProduct, UnclaimedRecipeProps } from '~/types/crafting';
import useSendContractMethod from '~/composables/useSendContractMethod';
import VueCountdown from '@chenfengyuan/vue-countdown';
import { tokenMetaData } from '~/utils';
import { AbiCoder, formatEther } from 'ethers';
import { BigNumber } from 'bignumber.js';

const { t } = useI18n();
const { blockchain } = useEnvs();
const { sendContractMethod } = useSendContractMethod();
const refreshListInjected = inject<() => void>('refreshList');
const store = useMainStore();

const props = defineProps<{
  recipe: UnclaimedRecipeProps;
  icon: string;
}>();

const timerTokensForClaimRef = ref<number>(0);
const tokensForClaimRef = ref<number>(0);
const confirmationNumberRef = ref<number>(0);
const isLoadingClaimButton = defineModel<boolean>({ default: false });

const craftStartStamp = computed(() => new Date(props.recipe.craftStartTime).getTime());
const craftEndStamp = computed(() => new Date(props.recipe.craftEndTime).getTime());

const countdownTimestamp = computed(() => craftEndStamp.value - Date.now());
const tokenMetadataComputed = computed(() => {
  if (!props.recipe) return null;

  return tokenMetaData(blockchain.contracts, props.recipe.products[0].tokenAddress);
});

const percent = ref<number>(calculateTimeLeftPercent(craftStartStamp.value, craftEndStamp.value, Date.now()));

const isClaimDisabled = computed(() => {
  const isTimerElaplsed = percent.value >= 100 && props.recipe.unclaimedModifier > 0;
  const isProgressOnAndHasReadyItems = percent.value < 100 && timerTokensForClaimRef.value > 0;
  if (isLoadingClaimButton.value) return true;
  return (isTimerElaplsed || isProgressOnAndHasReadyItems) && !isLoadingClaimButton.value ? false : true;
});

const trackCountdownProgress = (totalMilliseconds: number) => {
  percent.value = 100 - (totalMilliseconds / (props.recipe.craftDuration * 1000)) * 100;

  const calculatedValue = Math.floor(
    calculateTokensAvailable(totalMilliseconds, props.recipe.craftDuration * 1000, props.recipe.multiplyModifier)
  );

  if (calculatedValue < 0) {
    timerTokensForClaimRef.value = 0;
    return;
  }

  timerTokensForClaimRef.value = calculatedValue;
};

const tokenUniqueness = computed(() => {
  if (!props.recipe) return '';

  const product = props.recipe.products[0];
  const value = getNftValueByAddress(blockchain.contracts, product as RecipeProduct);

  return value;
});

const calculateTokensAvailable = (timeRemaining: number, totalDuration: number, totalTokens: number): number => {
  const elapsedTime = totalDuration - timeRemaining;
  const durationPerToken = totalDuration / totalTokens;

  return Math.floor(elapsedTime / durationPerToken);
};

const claimCraftedTokens = async () => {
  isLoadingClaimButton.value = true;
  try {
    confirmationNumberRef.value = 0;

    const { recipeId, userRecipeIndex } = props.recipe;
    const claimTx = await sendContractMethod({
      contract: 'crafting',
      address: blockchain.contracts.crafting,
      methodName: 'claimRecipe',
      methodArguments: [recipeId, userRecipeIndex]
    });

    if (!claimTx?.hash) return;

    const txReceipt = await claimTx?.wait();

    if (claimTx?.hash) {
      if (txReceipt) {
        const abiCoder = new AbiCoder();
        const amount = formatEther(abiCoder.decode(['uint'], txReceipt.logs[0].data)[0]);

        let description: string = '';

        // if (recipes.value?.[activity.index]) {
        const methodName = tokenMetaData(blockchain.contracts, props.recipe?.products[0]?.tokenAddress!)?.methodName;
        const isNFT =
          tokenMetaData(blockchain.contracts, props.recipe?.products[0]?.tokenAddress!).interface === 'erc721';

        description = `${t(methodName || '')} ${capitalizeFirstLetter(tokenUniqueness.value)}`;
        // }

        if (!isNFT) {
          description = `(${BigNumber(amount).decimalPlaces(2)} ${t(methodName || '')})`;
        }

        saveHashToLocalStorage(`${t('dashboardPurchasesClaimed')} ${description}`, claimTx.hash);
      }

      store.updateVersion();

      await claimTx?.wait(blockchain.minConfirmationsCount);

      const confirmations = await claimTx?.confirmations();

      if (confirmations >= blockchain.minConfirmationsCount && claimTx?.hash) {
        modifyHistoryHashStatus(claimTx.hash, 'Done');
      }
      tokensForClaimRef.value = 0;
      refreshListInjected && refreshListInjected();
    }
  } catch (error) {
    ElNotification.error(ERRORS_CONFIG.apiErrors.claimError.generic);
  } finally {
    isLoadingClaimButton.value = false;
    store.updateVersion();
  }
};
</script>

<style lang="scss" scoped>
.recipeRow {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 1em;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 4px 24px;
    width: auto;
    min-width: max-content;
    line-height: 0.5em;
    white-space: nowrap;
  }

  .left-block {
    width: 65%;
  }

  .fullWidth {
    width: 100%;
  }

  .recipeRowInfo {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;

    img {
      width: 33px;
      height: 33px;

      &[alt='nftChristmasShip'] {
        border-radius: 5px;
      }
    }

    .tokenName {
      margin-left: 5px;
      color: #fff;
      text-align: left;
      font-size: 16px;

      span {
        color: #49ddaa;
      }
    }
  }

  .recipeRowProgress {
    position: relative;
    border-radius: 8px;
    border: 1px solid #49ddaa;
    border-radius: 8px;
    margin-top: 2px;
    margin-bottom: 10px;
    height: 6px;
    overflow: hidden;
    display: flex;
    justify-content: space-evenly;
    transition: all 0.3s ease;
    background: linear-gradient(0deg, #010303, #17343e);

    .progressFill {
      height: 100%;
      width: 70%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(90deg, #01c0fe 0%, #60ff7c 100%);
    }
  }
}
</style>
