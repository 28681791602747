<template>
  <div class="confirm-popup">
    <div class="overlay" @click="handleToggleConfirmationModalInjected && handleToggleConfirmationModalInjected()" />
    <div class="confirm-content">
      <h2 class="confirm-content-title">{{ $t('craftingConfirmationTitle') }}</h2>
      <div
        class="confirm-close-btn"
        @click="handleToggleConfirmationModalInjected && handleToggleConfirmationModalInjected()"
      />

      <div>
        <h4 class="confirm-content-token-name">{{ $t(props.tokenMetadata.label) }}</h4>
        <p class="confirm-content-token-time">
          {{ $t('willBeReadyIn') }}:
          <vue-countdown v-slot="{ days, hours, minutes }" :time="readyTime" :auto-start="false">
            {{ BigNumber(days).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(days)}d` : '' }}
            {{ BigNumber(hours).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(hours)}h` : '' }}
            {{
              BigNumber(minutes).isGreaterThan(0) || BigNumber(hours).isGreaterThan(0)
                ? `${formatToZeroPrefixedNumber(minutes || 0)}m`
                : ''
            }}
          </vue-countdown>
        </p>
        <div v-for="product of recipe?.products" :key="product.tokenAddress" class="confirm-content-item">
          <img :src="store.craftingTokenImg" :alt="props.tokenMetadata.label" />
          <p v-if="!isNft">
            <span v-if="new BigNumber(product.quantityMin).eq(product.quantityMax)">
              {{ new BigNumber(product.quantityMax).multipliedBy(craftingMultiplier) }}
            </span>
            <span v-else>
              {{ new BigNumber(product.quantityMin).decimalPlaces(1).multipliedBy(craftingMultiplier) }}-{{
                new BigNumber(product.quantityMax).decimalPlaces(1).multipliedBy(craftingMultiplier)
              }}
            </span>
          </p>
          <p v-else class="valuable">
            {{ tokenTokenUniquenessFormatter(productValue) }}
          </p>
        </div>
      </div>

      <div>
        <p class="confirm-content-confirm-text" v-html="$t('craftingConfirmationText')" />
        <div class="confirm-content-checkbox">
          <el-checkbox id="agree-cb" v-model="agreeToTerms" type="checkbox" class="checkbox" size="large">
            {{ $t('craftingCheckboxAgree') }}
          </el-checkbox>
        </div>
        <button
          class="btn-primary produce"
          :disabled="!agreeToTerms || isItemCraftingInjected"
          @click="produceItemInjected && produceItemInjected()"
        >
          {{ $t('craftingProduce') }} &nbsp;<span v-if="confirmationNumberRefInjected && isItemCraftingInjected"
            >{{ confirmationNumberRefInjected }} / {{ blockchain.minConfirmationsCount }}</span
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, defineProps } from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';

import useEnvs from '~/composables/useEnvs';
import type { BuildingProps, RecipeProduct, RecipeProps, RunningRecipeProps } from '~/types/crafting';
import { TokenUniquenessType, type TTokensConfigItem } from '~/utils/constants';
import { BigNumber } from 'bignumber.js';
import { useI18n } from '#imports';
import { useMainStore } from '~/stores/mainStore';

const { t } = useI18n();
const { blockchain, apiUrl } = useEnvs();
const building = inject<BuildingProps>('building');
const productValue = ref('');
const store = useMainStore();

const props = defineProps<{
  tokenMetadata: TTokensConfigItem;
  recipe?: RecipeProps;
  craftingMultiplier: number;
  shipIndex?: number;
}>();

const { data: runningRecipes } = await useFetch<RunningRecipeProps[]>(() => `/crafting/recipes/in-queue`, {
  baseURL: apiUrl,
  query: {
    recipeId: props.recipe?.recipeId,
    buildingHash: building?.buildingHash
  }
});

const readyTime = computed(
  () =>
    (BigNumber(new Date(runningRecipes.value?.at(-1)?.craftEndTime || '0').getTime() - Date.now()).isPositive()
      ? new Date(runningRecipes.value?.at(-1)?.craftEndTime || '0').getTime() - Date.now()
      : 0) +
    (props.recipe?.duration || 0) * 1000 * props.craftingMultiplier
);

const isNft = computed(() => {
  if (!props.recipe) return false;

  return (
    props.recipe &&
    props.recipe.products.every(
      (product: RecipeProduct) => product.nftValueMin !== null && product.nftValueMax !== null
    )
  );
});

const agreeToTerms = ref(false);
const handleToggleConfirmationModalInjected = inject<() => void>('handleToggleConfirmationModal');
const produceItemInjected = inject<() => void>('handleProduceItem');
const confirmationNumberRefInjected = inject<number>('confirmationNumberRef');
const isItemCraftingInjected = inject<boolean>('isItemCrafting');

function getItemValue() {
  if (!props.recipe) return '';

  const product = props.recipe.products[0];
  const value = getNftValueByAddress(blockchain.contracts, product);

  productValue.value = value;
}

getItemValue();

// TODO: need refactoring
function tokenTokenUniquenessFormatter(key: TokenUniquenessType | string): string {
  switch (key) {
    case TokenUniquenessType.Common:
      return t('coreCommon');
    case TokenUniquenessType.Uncommon:
      return t('coreUncommon');
    case TokenUniquenessType.Rare:
      return t('coreRare');
    default:
      return '';
  }
}
</script>

<style lang="scss" scoped>
.confirm-popup {
  position: absolute;
  top: 0;
  left: 0;

  z-index: 1999;
  height: 100%;
  width: 100%;

  overflow: hidden;
  border-radius: 15px;

  display: flex;
}

.confirm-close-btn {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 9999;
  background-image: url('~/public/img/icons/bone_close.svg');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;

  &:hover {
    opacity: 0.6;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.7;
}

.confirm-content {
  position: relative;
  z-index: 3;
  background-color: rgba(2, 27, 38);
  width: 100%;
  max-width: 683px;
  margin: auto;
  padding: 10px;
  border-radius: 10px;

  @media screen and (max-width: 900px) {
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
  }

  &-title {
    font-family: Grenze;
    font-size: 32px;
    font-weight: 700;
    line-height: 47.36px;
    text-align: center;
    margin: 35px 0 25px;
    color: #fff;

    @media screen and (max-width: 479px) {
      font-size: 28px;
    }
  }

  &-token-name {
    font-family: Grenze;
    font-size: 26px;
    font-weight: 700;
    line-height: 38.48px;
    text-align: center;
  }

  &-token-time {
    font-family: Grenze;
    font-size: 24px;
    font-weight: 600;
    line-height: 26.4px;
    text-align: center;
    color: #49ddaa;
  }

  &-confirm-text {
    font-family: Grenze;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    max-width: 577px;
    margin: auto;
    color: #fff;

    > * {
      color: #0083ad !important;
    }

    @media screen and (max-width: 479px) {
      font-size: 14px;
    }
  }

  &-checkbox {
    font-size: 20px;
    font-weight: 500;
    line-height: 29.6px;
    text-align: left;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Grenze;

    @media screen and (max-width: 479px) {
      margin: 10px auto;
    }
  }

  &-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 11px 13px;
    padding-bottom: 0px;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 35px;
    max-width: fit-content;
    border: 1px solid #1e353e;
    border-radius: 12px;
    background: #041a27;
    box-shadow: 2px 4px 5px 0px #00000040;
    background: linear-gradient(180deg, #0083ad 0%, #00354f 100%);

    @media screen and (max-width: 900px) {
      width: max-content;
      height: unset;

      min-width: 120px;
      aspect-ratio: 1/1;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
      width: 100%;
      font-weight: 700;
      font-size: 24px;
      position: relative;
      font-family: Grenze;
      top: -10px;
      color: #fff;
    }

    img {
      height: 70px;
      width: 70px;
      border-radius: 7px;
    }

    .valuable {
      color: #ffe604;
      font-size: 1.2rem;
      padding-top: 12px;
      text-transform: capitalize;
    }
  }

  .btn-primary {
    display: block;
    margin: auto;
    width: 200px;

    &.produce {
      @media screen and (max-width: 900px) {
        width: 80%;
      }
    }
  }
}
</style>
