<template>
  <el-tooltip ref="refPopper" popper-class="customPopper" effect="dark" :show-after="200">
    <template #content>
      <div v-if="isTooltipVisible" ref="refContent" class="tokenTooltip" @mouseleave="handleMouseLeave">
        <h4 class="tooltipTitle">
          <div class="imgContainer">
            <img :src="props?.nftToken?.imageUrl" :alt="props.nftToken?.name" />
          </div>
          {{ $t(getTranslationKey(props.nftToken.name) || '') }}
        </h4>
        <div class="tooltipDescription">{{ $t(props.nftToken.description || '') }}</div>
      </div>
    </template>
    <storage-item :token-media="props.nftToken.imageUrl" :earned-value="'1'" @mouseenter="handleMouseEnter" />
  </el-tooltip>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import type { TokenDescription } from '~/types/token';
import { getTranslationKey } from '~/components/Storage/translationHelper';

const props = defineProps<{
  nftToken: TokenDescription;
}>();

const isTooltipVisible = ref<boolean>(false);
const refPopper = ref();
const refContent = ref();

const handleMouseEnter = () => {
  isTooltipVisible.value = true;
};

const handleMouseLeave = () => {
  isTooltipVisible.value = false;
};
</script>

<style lang="scss" scoped>
.tokenTooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 430px;
}

.tooltipTitle {
  display: flex;
  gap: 10px;
  color: #88e1ff;
  align-items: center;

  .imgContainer {
    width: 30px;
    height: 45px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.tooltipDescription {
  font-size: 20px;
  line-height: 26px;
  color: #8e979c;
}
</style>
