export const path = {
  withAuth: {
    referral: '/referral'
  },
  public: {
    home: '/',
    game: '/game',
    dashboard: '/dashboard',
    marketplace: '/marketplace',
    districts: '/districts',
    story: '/story'
  }
};
