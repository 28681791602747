export default (placeInSeason: number) => {
  if (placeInSeason > 150) return 0;
  switch (placeInSeason) {
    case placeInSeason >= 100 && placeInSeason <= 150 ? placeInSeason : null:
      return 10;
    case placeInSeason >= 51 && placeInSeason <= 100 ? placeInSeason : null:
      return 25;
    case placeInSeason >= 41 && placeInSeason <= 50 ? placeInSeason : null:
      return 50;
    case placeInSeason >= 31 && placeInSeason <= 40 ? placeInSeason : null:
      return 75;
    case placeInSeason >= 21 && placeInSeason <= 30 ? placeInSeason : null:
      return 100;
    case placeInSeason >= 11 && placeInSeason <= 20 ? placeInSeason : null:
      return 125;
    case placeInSeason === 10 ? placeInSeason : null:
      return 150;
    case placeInSeason === 9 ? placeInSeason : null:
      return 200;
    case placeInSeason === 8 ? placeInSeason : null:
      return 250;
    case placeInSeason === 7 ? placeInSeason : null:
      return 300;
    case placeInSeason === 6 ? placeInSeason : null:
      return 350;
    case placeInSeason === 5 ? placeInSeason : null:
      return 500;
    case placeInSeason === 4 ? placeInSeason : null:
      return 600;
    case placeInSeason === 3 ? placeInSeason : null:
      return 750;
    case placeInSeason === 2 ? placeInSeason : null:
      return 1000;
    case placeInSeason === 1 ? placeInSeason : null:
      return 1500;
    default:
      return 0;
  }
};
