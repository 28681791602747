<template>
  <div v-if="isShowOverlay" class="loading-wrapper">
    <div class="loading-overlay" :class="{ 'loading-overlay-hide': isLoadingFinished }" />

    <div class="loading-progress" :class="{ 'loading-progress-hide': isLoadingFinished }">
      <el-progress :stroke-width="3" :percentage="percentage" :show-text="false" :color="'#27E5FF'" />
    </div>

    <div class="layers">
      <div class="layer1" :class="{ 'layer1-hide-left': isLoadingFinished }" />
      <div class="layer2" />
      <div class="layer3" />
      <div class="layer4" :class="{ 'layer4-hide-left': isLoadingFinished }" />
      <div class="layer5" :class="{ 'layer5-hide-right': isLoadingFinished }" />

      <div class="logo-layer">
        <img height="101" src="~/public/img/logo-dark.svg" />
        <div class="title" v-html="$t('logoSubtitleMobile')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { loading } = defineProps<{
  loading: boolean;
}>();

const isLoadingFinished = ref<boolean>(false);
const isShowOverlay = ref<boolean>(true);

watch(
  () => loading,
  (isFinished, isStarted) => {
    if (!isFinished && isStarted) {
      isLoadingFinished.value = true;

      setTimeout(() => {
        isShowOverlay.value = false;
      }, 1000);
    }
  }
);
const percentage = ref(100);
</script>

<style lang="scss" scoped>
.logo-layer {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  opacity: 0;
  animation:
    show-logo 1.5s 2.5s linear forwards,
    hide-element 1.5s 5s linear forwards;
}

@keyframes show-logo {
  to {
    visibility: visible;
    opacity: 1;
  }
}

.title {
  font-family: Grenze;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #1e353e;
  width: 332px;
  max-width: 332px;
  white-space: nowrap;

  @media screen and (max-width: 376px) {
    font-size: 24px;
  }
}

.loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100dvh;
  width: 100dvw;
  z-index: 999999;
}

.loading-overlay {
  height: 100vh;
  width: 100vw;
  background-image: url('~/public/img/loadingOverlay/mountains.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  animation: scaling 4s 5s linear forwards;
}

.loading-overlay-hide {
  animation: hide 1.5s linear forwards;
}

.loading-progress-hide {
  animation: hide-element 0.2s linear forwards;
}

.layers {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.layers > .layer1-hide-left {
  transform: translateX(-65%);
  opacity: 1;
  animation: hide-to-left 1s linear forwards;
}

.layers > .layer4-hide-left {
  transform: translateX(-60%);
  opacity: 1;
  animation: hide-to-left 1s linear forwards;
}

.layers > .layer5-hide-right {
  transform: translateX(65%);
  opacity: 1;
  animation: hide-to-right 1s linear forwards;
}

.layer1 {
  position: absolute;
  background-image: url('~/public/img/loadingOverlay/clouds/layer1.png');
  width: 460px;
  height: 175px;
  max-width: 507px;
  bottom: 0;
  left: 0;
  transform: translateX(-50%);
  opacity: 0;
  animation:
    move-layer-1 2s linear forwards,
    show 1s linear forwards,
    move-layer-1-after-showing-logo 1s 5s linear forwards;
}

@keyframes move-layer-1-after-showing-logo {
  to {
    transform: translateX(-65%);
  }
}

@keyframes continue-move-layer-1 {
  to {
    transform: translateX(-55%);
  }
}

@keyframes move-layer-1 {
  to {
    transform: translateX(-55%);
  }
}

.layer2 {
  position: absolute;
  background-image: url('~/public/img/loadingOverlay/clouds/layer2.png');
  width: 690px;
  height: 315px;
  top: 65%;
  left: 0;
  transform: translate(-15%, -65%);
  opacity: 0;
  animation:
    move-layer-2 2s linear forwards,
    show 1s linear forwards,
    hide-element 1.5s 5s linear forwards;
}

@keyframes move-layer-2 {
  to {
    transform: translate(-5%, -65%);
  }
}

.layer3 {
  position: absolute;
  background-image: url('~/public/img/loadingOverlay/clouds/layer3.png');
  width: 815px;
  height: 370px;
  top: 50%;
  right: 0;
  transform: translate(15%, -50%);
  z-index: 3;
  opacity: 0;
  animation:
    move-layer-3 2s linear forwards,
    show 1s linear forwards,
    hide-element 1.5s 5s linear forwards;

  @media screen and (max-width: 479px) {
    right: -30%;
  }
}

@keyframes move-layer-3 {
  to {
    transform: translate(5%, -50%);
  }
}

.layer4 {
  position: absolute;
  background-image: url('~/public/img/loadingOverlay/clouds/layer4.png');
  width: 330px;
  height: 145px;
  top: 100px;
  left: 0;
  transform: translateX(-50%);
  z-index: 2;
  opacity: 0;
  animation:
    show 1s linear forwards,
    move-layer-4-after-showing-logo 1s 5s linear forwards;
}

@keyframes move-layer-4-after-showing-logo {
  to {
    transform: translateX(-60%);
  }
}

@keyframes move-layer-4 {
  to {
    transform: translateX(-100%);
  }
}

.layer5 {
  position: absolute;
  background-image: url('~/public/img/loadingOverlay/clouds/layer5.png');
  width: 300px;
  height: 140px;
  right: 0;
  top: 0;
  transform: translateX(30%);
  opacity: 0;
  animation:
    move-layer-5 2s linear forwards,
    show 1s linear forwards,
    move-layer-5-after-showing-logo 1s 5s linear forwards;
}

@keyframes move-layer-5-after-showing-logo {
  to {
    transform: translateX(65%);
  }
}

@keyframes move-layer-5 {
  to {
    transform: translateX(50%);
  }
}

@keyframes show {
  to {
    opacity: 100%;
  }
}

@keyframes hide {
  to {
    opacity: 0;
  }
}

@keyframes scaling {
  to {
    scale: 1.1;
  }
}

@keyframes hide-element {
  to {
    opacity: 0;
  }
}

@keyframes hide-to-left {
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes hide-to-right {
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
</style>

<style style lang="scss">
.loading-progress {
  position: fixed;
  z-index: 1;
  animation: progress-animation 3s linear forwards;
  width: 0;

  .el-progress-bar__outer {
    margin: 23px 16px;
    background-color: transparent;
  }
}

@keyframes progress-animation {
  to {
    width: 100%;
  }
}
</style>
