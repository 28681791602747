export default (futureDate: ComputedRef<Date>, updateFrequency: 'second' | 'hour') => {
  const timeLeft = {
    seconds: ref(0),
    minutes: ref(0),
    hours: ref(0),
    days: ref(0),
    months: ref(0),
    ended: ref(false)
  };

  const intervalValue = {
    second: 1000,
    hour: 3600000
  }[updateFrequency];

  const calculateTimeLeft = () => {
    const timeRemaining = Date.parse(futureDate.value.toString()) - Date.now();
    if (timeRemaining < 0) {
      clearCountdown();
      timeLeft.ended.value = true;
      return timeLeft;
    }
    timeLeft.seconds.value = Math.floor((timeRemaining / 1000) % 60);
    timeLeft.minutes.value = Math.floor((timeRemaining / 1000 / 60) % 60);
    timeLeft.hours.value = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);

    timeLeft.days.value = Math.ceil(timeRemaining / (1000 * 60 * 60 * 24));

    timeLeft.months.value = Math.floor(timeLeft.days.value / 30);

    return timeLeft;
  };

  let interval: NodeJS.Timeout;
  onNuxtReady(() => {
    interval = setInterval(calculateTimeLeft, intervalValue);
  });

  const clearCountdown = () => {
    clearInterval(interval);
  };

  watch(
    futureDate,
    () => {
      if (import.meta.client) {
        clearCountdown();
        interval = setInterval(calculateTimeLeft, intervalValue);
      }
    },
    { immediate: true }
  );

  return {
    timeLeft,
    clearCountdown
  };
};
