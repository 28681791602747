import { capitalizeFirstLetter } from '~/utils/formatters';

export function transformNameToCamelCase(nftName: string): string {
  const splitedNftName = nftName.split('-');

  const camelCaseResult = splitedNftName.reduce((previousValue, currentValue) => {
    previousValue += capitalizeFirstLetter(currentValue);
    return previousValue;
  }, '');

  return camelCaseResult;
}

export function getTranslationKey(key: string): string {
  if (!key.includes('-')) return `core${capitalizeFirstLetter(key)}`;

  return `core${transformNameToCamelCase(key)}`;
}
