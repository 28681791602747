<template>
  <div class="container">
    <div class="tokensContainer">
      <StorageMobileListItem v-for="token of nonNftTokens" :key="token.id" :token="token" @close="emit('close')" />
      <StorageMobileListNftItem
        v-for="nftToken of props.nftTokens"
        :key="nftToken.imageUrl"
        :nft-token="nftToken"
        @close="emit('close')"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue';
import type { ITokenModel } from '~/types/apiService';
import type { TokenDescription } from '~/types/token';

const emit = defineEmits(['close']);

const props = defineProps<{
  tokens: ITokenModel[];
  nftTokens: TokenDescription[];
}>();

const nonNftTokens = computed(() => props.tokens.filter((token) => !token.isNft));
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
}

.title {
  color: #90989d;
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  font-family: Grenze, sans-serif;
}

.tokensContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  margin-top: 20px;
}
</style>
