<template>
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_10440_86508)">
      <path d="M1 5L6 10L11.5 5" stroke="white" />
      <path d="M1 1L6 6L11.5 1" stroke="white" />
    </g>
    <defs>
      <clipPath id="clip0_10440_86508">
        <rect width="12" height="11" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
