import { useRuntimeConfig } from 'nuxt/app';
import appConfig from '../env.config.json';
import type { BlockchainConfig } from '~/types/contractAddresses';

type TAppConfig = {
  apiUrl: string;
  landingUrl: string;
  jackpotValue: number;
  jackpotWinPlaces: number;
  blockchain: BlockchainConfig;
  featureFlags: Record<string, boolean>;
};

export default () => {
  const config = useRuntimeConfig();
  const env = config.public.env as keyof typeof appConfig;

  if (env in appConfig) {
    return appConfig[env as keyof typeof appConfig] as TAppConfig;
  }
  throw Error(`Unknown ENV=${env} environment variable`);
};
