<template>
  <div class="container">
    <p class="description" v-html="description" />
    <div class="tokensContainer">
      <div
        v-for="token in tokens"
        :key="token.name"
        class="token"
        @click="clipboardHandler(token.tokenContractAddress)"
      >
        <div class="leftBlock">
          <img :src="getIcon(0, token.tokenContractAddress)" alt="token-icon" />
          <span class="tokenName">{{ token.name }}</span>
        </div>
        <div class="rightBlock">
          {{ formatAddress(token.tokenContractAddress) }}
          <img src="/img/icons/copy-token.svg" alt="copy-icon" />
        </div>
      </div>
    </div>
    <p class="howToUse">
      <span> {{ $t('headerTokenAddressUseThe') }}</span
      >&nbsp;<span class="link" @click="showConfirmation = !showConfirmation">
        {{ $t('headerTokenAddressGuideTo') }}
        <img src="/img/icons/guide-metamask.svg" alt="open-icon" />
      </span>
    </p>
    <el-dialog
      v-if="showConfirmation"
      v-model="showConfirmation"
      :title="$t('headerTokenAddressInfoConfirmTitle')"
      :fullscreen="$device.isMobile"
      :append-to-body="true"
      width="100%"
      :class="{ 'ru-locale': $i18n.locale === 'ru' }"
    >
      <GeneralTokenAddressInfoConfirmation
        :description="$t('headerTokenAddressInfoConfirmDescription')"
        @close="showConfirmation = false"
      />
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import type { ITokenModel } from '~/types/apiService';
import { formatAddress } from '~/utils';
import { copyToClipboard } from '~/utils';
import { useI18n } from '#imports';

const { t } = useI18n();
const showConfirmation = ref(false);

defineProps<{
  description: string;
  tokens: ITokenModel[];
}>();

const clipboardHandler = (value: string) => copyToClipboard(value, t('coreCopied'));
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  font-family: Grenze, sans-serif;
}

.description {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  color: #90989d;
  margin-top: 0px;
}

.tokensContainer {
  height: 55vh;
  overflow-y: scroll;
  margin: 20px 0px;
  font-family: Eczar, sans-serif;

  .token {
    display: flex;
    align-items: center;
    padding: 0px 17px;
    background-color: #1e353e;
    border-radius: 6px;
    color: #fff;
    height: 48px;
    font-weight: 600;
    justify-content: space-between;

    @media screen and (max-width: 400px) {
      padding: 0px 5px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .tokenName {
      text-transform: uppercase;
    }
  }

  .leftBlock {
    img {
      margin-right: 5px;
    }
  }

  .rightBlock {
    img {
      margin-left: 5px;
    }
  }

  .leftBlock,
  .rightBlock {
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
    }
  }
}

.howToUse {
  font-size: 20px;
  color: #90989d;
  text-align: center;

  .link {
    color: #88e1ff;
    text-decoration: underline;
  }
}
</style>
