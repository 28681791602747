<template>
  <UIModalInnerContentWrapper
    ref="scrollContainer"
    :class="{ maxHeightLeaderboard: $device.isMobile && !isJackpot }"
    class="contentWrapperExtraStyle"
  >
    <div class="leaderboardContainer">
      <div v-if="rewardsData && Object.keys(rewardsData).length > 0 && !isNoPrizeSticky && !isJackpot" class="prize">
        <span>{{ $t('seasonGrandPrize') }}:</span>
        <div v-for="(tokenAddress, i) of tokenAddresses" :key="tokenAddress" class="prizeItem">
          <img
            width="36"
            :src="tokens?.[tokenMetaData(blockchain.contracts, tokenAddress)?.name.contract as Tokens]?.toString()"
            alt=""
          />
          <span v-if="!rewardsConfig[i][4]">{{ prizesPool?.[i] }}</span>
        </div>
      </div>
      <div
        v-else-if="
          rewardsData && isNoPrizeSticky && leaderboardData?.length > Object.keys(rewardsData).length && !isJackpot
        "
        class="noPrizeBanner filled"
      >
        {{ $t('noPrize') }}
      </div>
      <div
        v-else-if="!isJackpot"
        style="height: 45px; width: 100%; position: sticky; top: 0; background: #040e13"
      ></div>

      <div
        v-if="
          rewardsData &&
          Object.keys(rewardsData).length &&
          leaderboardData?.toString()?.length > 0 &&
          leaderboardData.length > Object.keys(rewardsData).length &&
          !isJackpot
        "
        ref="noPrizeEl"
        class="noPrize"
        :style="{ order: Object.keys(rewardsData).length + 1 }"
      >
        {{ $t('noPrize') }}
      </div>
      <div v-if="isShowStickyBgTop" class="stickyBgTop" :class="{ withoutPrizeRow: isJackpot }"></div>

      <SeasonLeaderboardItem
        v-for="(item, i) of leaderboardData"
        :key="`${item[0]}${item[1]}`"
        :address="item[0]"
        :rewards="rewardsData?.[i + 1]"
        :style="{ order: i + 1 }"
        :score="BigNumber(item[1]).toNumber()"
        :position="i + 1"
        :own="item[0] === address"
        :leaderboard-length="leaderboardData.length"
        :is-jackpot="isJackpot"
        :jackpot-score-in-year="
          props.isJackpot && typeof checkedHistoricalSeason !== 'number'
            ? BigNumber(item[1] || 0).toNumber()
            : undefined
        "
        :checked-historical-season="checkedHistoricalSeason"
        :class="{
          withoutPrizeRow: isJackpot,
          ownPosition: item[0] === address,
          prizePosition:
            i <
            (isJackpot && typeof checkedHistoricalSeason !== 'number'
              ? jackpotWinPlaces
              : Object.keys(rewardsData || {}).length)
        }"
        @change-show-sticky-bg-top="
          (isShow) => {
            isShowStickyBgTop = isShow;
          }
        "
        @change-show-sticky-bg-bottom="
          (isShow) => {
            isShowStickyBgBottom = isShow;
          }
        "
      />
      <div v-if="isShowStickyBgBottom" :style="{ order: leaderboardData.length + 4 }" class="stickyBgBottom"></div>
    </div>
  </UIModalInnerContentWrapper>
</template>
<script setup lang="ts">
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { ZeroAddress } from 'ethers';
import { apiUrls, Tokens } from '~/utils/constants';
import { BigNumber } from 'bignumber.js';
import tokens from '~/public/img/tokens';
import type { Reward } from '~/types/season';
const emit = defineEmits(['loadingSwitcher']);
const { blockchain, apiUrl, jackpotWinPlaces } = useEnvs();
const { getContractReadOnly } = useAbiAccess();
const { address } = useWeb3ModalAccount();
const seasonId = inject<number>('currentSeasonId');
const props = defineProps<{ checkedHistoricalSeason?: number; isJackpot: boolean }>();
const currentSeasonId = computed(() =>
  typeof props.checkedHistoricalSeason !== 'number' ? seasonId : props.checkedHistoricalSeason
);

const noPrizeEl = ref<HTMLElement | null>(null);
const scrollContainer = ref<HTMLElement | null>(null);

const isShowStickyBgTop = ref(false);
const isShowStickyBgBottom = ref(false);

const isNoPrizeSticky = ref(false);

const { data: rewardsSeason } = useFetch<Record<string, Reward[]>>(
  () => apiUrls.seasons.positionsRewards(BigNumber(currentSeasonId.value || 0).toNumber()),
  {
    baseURL: apiUrl,
    immediate: true,
    watch: [address, currentSeasonId],
    onRequest() {
      emit('loadingSwitcher');
    },
    onResponse() {
      emit('loadingSwitcher');
    }
  }
);

const seasonContract = await getContractReadOnly('season', blockchain.contracts.season);
const seasonYearContract = await getContractReadOnly(
  'SEASON_REWARDS_YEAR_1',
  blockchain.contracts.SEASON_REWARDS_YEAR_1
);
const currentSeasonYear = inject<number>('currentSeasonYear');

const { data: rewardsJackpot } = useFetch<Record<string, Reward[]>>(
  apiUrls.jackpot.positionsRewards(currentSeasonYear!),
  {
    baseURL: apiUrl,
    immediate: true,
    watch: [address]
  }
);

const rewardsData = computed(() =>
  props.isJackpot && typeof props.checkedHistoricalSeason !== 'number' ? rewardsJackpot.value : rewardsSeason.value
);

const leaderboardData = asyncComputed(async () =>
  props.isJackpot && typeof props.checkedHistoricalSeason !== 'number'
    ? await seasonContract?.getUserScoresBySeasonYear(currentSeasonYear, ZeroAddress, 1000)
    : await seasonContract?.getUserScoresBySeason(currentSeasonId.value, ZeroAddress, 1000)
);

const rewardsConfig = asyncComputed(async () =>
  props.isJackpot
    ? await seasonContract?.getJackpotRewardsConfig()
    : await seasonYearContract?.getSeasonRewardsConfig(currentSeasonId.value)
);

const tokenAddresses = computed<string[]>(() => rewardsConfig.value?.map((item: string[]) => item?.[0]));

const prizesPool = computed(() => {
  if (!rewardsData.value) return;
  return tokenAddresses.value.map((item: string) =>
    Object.values(rewardsData.value || {})
      .flat()
      .filter((entry) => entry.tokenAddress === item)
      .reduce((prev, cur) => prev + cur.amount, 0)
  );
});

onMounted(() => {
  if (noPrizeEl.value) {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].rootBounds &&
          entries[0].intersectionRect.bottom <= entries[0].rootBounds.top + 50 &&
          entries[0].intersectionRect.top === entries[0].rootBounds.top
        ) {
          isNoPrizeSticky.value = true;
        } else {
          isNoPrizeSticky.value = false;
        }
      },
      { threshold: [0.5, 0.75, 1], root: noPrizeEl.value?.parentNode?.parentNode as HTMLElement }
    );

    intersectionObserver.observe(noPrizeEl.value as HTMLElement);
  }
});
</script>

<style lang="scss" scoped>
.contentWrapperExtraStyle {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  border-radius: 12px;
  height: 100%;
  max-height: 50vh;

  @media screen and (max-width: 560px) {
    width: 100vw;
    max-height: 100%;

    &.maxHeightLeaderboard {
      max-height: 60dvh;
    }
  }
}

.leaderboardContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;

  .prizePosition {
    border-color: #867927;
  }

  .filled {
    background: linear-gradient(180deg, #071821 0%, #040e13 100%);
  }

  .prize {
    display: flex;
    column-gap: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: center;
    align-items: center;
    position: sticky;
    flex-wrap: wrap;
    top: 0;
    background: linear-gradient(180deg, #071821 0%, #040e13 100%);
    z-index: 2;

    @media screen and (max-width: 560px) {
      min-height: 80px;
    }

    & * {
      color: #ffce1e;
      font-family: Grenze, sans-serif;
      font-size: 22px;
    }
  }

  .prizeHeading {
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
    }
  }

  .noPrizeBanner,
  .noPrize {
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: #88e1ff;
  }

  .noPrizeBanner {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  .prizeItem {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}

.stickyBgTop,
.stickyBgBottom {
  position: sticky;
  width: 100%;
  background: #040e13;
  animation: fade-in 0.3s ease-in forwards;
  z-index: 1;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.stickyBgTop {
  top: 45px;
  height: 50px;

  @media screen and (max-width: 767px) {
    top: 75px;
  }

  &.withoutPrizeRow {
    height: 60px;
    top: -10px;
  }
}

.stickyBgBottom {
  bottom: -28px;
  height: 60px;
}
</style>

<style>
.ownPosition {
  z-index: 1;

  position: sticky;
  top: 50px;
  bottom: -20px;
  z-index: 2;

  @media screen and (max-width: 560px) {
    top: 80px;
  }
  &.withoutPrizeRow {
    top: 5px;
  }
}
</style>
