import type { Contract } from 'ethers';
import type { BuildingProps, RecipeProps, UserActivityAvailableForClaim } from '~/types/crafting';

export default async function getUserActivities(
  recipes: RecipeProps[],
  building: BuildingProps,
  address: string,
  craftingContract: Contract
) {
  let formattedValue: UserActivityAvailableForClaim[][] = [];

  try {
    const userActivity = await Promise.all(
      recipes?.map((recipe: RecipeProps) =>
        craftingContract?.getUserRecipeStatuses(address, recipe?.recipeId, 0, 100)
      ) || []
    );

    formattedValue = userActivity.map((recipeActivity, index) =>
      recipeActivity
        .map((activity: [number, number, number, number, string, number, RecipeProps]) => {
          return {
            index,
            date: Number(activity[0]),
            total: Number(activity[1]),
            acquired: Number(activity[2]),
            available: Number(activity[3]),
            buildingHash: activity[4],
            id: Number(activity[5])
          };
        })
        .filter((item: UserActivityAvailableForClaim) => {
          return item.buildingHash === building.buildingHash && item.available > 0;
        })
    );
    return formattedValue;
  } catch (error) {
    console.error(error);
  }

  return formattedValue;
}
