<template>
  <div class="running-recipes">
    <div v-if="!!runningRecipes?.length" class="running-recipes-header">
      <p>{{ $t('craftingRunningRecipes') }}:</p>
      <el-checkbox id="agree-cb" v-model="onlyMineRecipes" type="checkbox" class="checkbox" size="large">
        {{ $t('craftingShowOnlyMine') }}
      </el-checkbox>
    </div>
    <div v-if="!!runningRecipes?.length && runningRecipe" class="running-recipes-list">
      <CraftingAvailableTokensForClaim
        v-if="!(onlyMineRecipes && runningRecipe.userAddress !== address)"
        :token-metadata="props.tokenMetadata"
        :running-recipe="runningRecipe"
        :recipe-id="props.recipeId"
      />
      <CraftingRunningRecipeRow
        v-for="pendingRecipe in recipesPending"
        :key="pendingRecipe.transactionHash"
        :transaction-hash="pendingRecipe.transactionHash"
        :token-metadata="props.tokenMetadata"
      />
      <CraftingRunningRecipeRow
        v-for="recipe in filteredRecipes?.sort(
          (rec, recNenx) => new Date(rec.craftStartTime).getTime() - new Date(recNenx.craftStartTime).getTime()
        )"
        :key="recipe.craftStartTime"
        :running-recipe="runningRecipe"
        :recipe="recipe"
        :token-metadata="props.tokenMetadata"
      />
    </div>
    <div v-else>
      <h2 class="running-recipes-empty">{{ $t('craftingEmptyRunningRecipes') }}</h2>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, computed, inject, provide } from 'vue';
import { useFetch } from 'nuxt/app';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';

import { CraftingRunningRecipeRow, CraftingAvailableTokensForClaim } from '#components';
import { useEnvs } from '#imports';
import type { BuildingProps } from '~/types/crafting';
import { type TTokensConfigItem } from '~/utils/constants';
import type { RunningRecipeProps, RecipesPendingProps } from '~/types/crafting';

const { address } = useWeb3ModalAccount();
const { apiUrl } = useEnvs();

const onlyMineRecipes = ref(false);

const props = defineProps<{
  recipeId: string;
  tokenMetadata: TTokensConfigItem;
}>();

const building = inject<BuildingProps>('building');
const currentTime = computed(() => Date.now());

const computedRecipeId = computed(() => props.recipeId);

const { data: runningRecipes, refresh } = useFetch<RunningRecipeProps[]>(() => `/crafting/recipes/in-queue`, {
  baseURL: apiUrl,
  query: {
    recipeId: computedRecipeId,
    buildingHash: building?.buildingHash
  }
});

provide('refreshRunningRecipes', refresh);

const { data: recipesPending } = useFetch<RecipesPendingProps[]>(() => `/crafting/recipes/pending`, {
  baseURL: apiUrl,
  query: {
    recipeId: props.recipeId,
    buildingHash: building?.buildingHash,
    userAddress: address.value
  }
});

const currentUserRecipes = computed(() => {
  return runningRecipes.value?.filter((recipe) => recipe.userAddress === address.value);
});

const getRecipeTime = (time: string) => new Date(time).getTime();

const runningRecipe = computed(() => {
  return runningRecipes.value?.find((recipe) => {
    console.log(recipe.craftStartTime, new Date(currentTime.value));
    return (
      getRecipeTime(recipe.craftStartTime) <= currentTime.value &&
      currentTime.value <= getRecipeTime(recipe.craftEndTime)
    );
  });
});

const filteredRecipes = computed(() => {
  const recipes = onlyMineRecipes.value ? currentUserRecipes.value : runningRecipes.value;

  return recipes?.filter((recipe) => Date.now() < getRecipeTime(recipe.craftStartTime));
});
</script>

<style lang="scss">
.running-recipes {
  border: 1px solid #1e353e;
  margin: 16px;
  background: #0d242f;
  border-radius: 15px;
  @media screen and (max-width: 900px) {
    border: none;
    margin: 0;
    background: none;
    border-radius: 0;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px;

    p {
      margin: 0;
      color: #fff;
      font-family: Grenze;
      font-weight: 500;
      font-size: 18px;
    }
  }

  &-empty {
    text-align: center;
    color: #fff;
  }

  &-list {
    overflow-y: auto;
    max-height: 193px;
    @media screen and (max-width: 900px) {
      max-height: unset;
    }
    padding: 14px;
    padding-top: 0;
  }
}
</style>
