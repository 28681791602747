<template>
  <div class="seasonEndContainer">
    <div v-if="modalView === SeasonModalView.end && position && !BigNumber(position).isZero()" class="statisticWrapper">
      <div class="statisticItem">
        <span class="label">{{ $t('placeInSeason') }}:</span>
        <span class="value">
          <img src="/img/season_cup.svg" width="45" height="45" alt="" />
          {{ seasonPosition }}
        </span>
      </div>

      <div class="statisticItem">
        <span class="label">{{ $t('seasonPoints') }}:</span>
        <span class="value">
          <img :src="tokens.seasonPoints" width="45" height="45" alt="" />
          {{ userScore?.[1] }}
        </span>
      </div>
    </div>
    <button v-if="modalView === SeasonModalView.end" class="btn-primary" @click="openHandler">
      {{ $t('continue') }}!
    </button>

    <el-carousel
      v-if="seasonRewards && modalView === SeasonModalView.rewards"
      :height="$device.isMobile ? '100%' : '200px'"
      :arrow="'always'"
      :loop="false"
      :indicator-position="'none'"
      :motion-blur="true"
      :autoplay="false"
      :initial-index="BigNumber(currentSeason!).minus(1).toNumber()"
      @change="onSeasonSlideChange"
    >
      <el-icon-arrow-left
        v-if="seasonToCheckRewards === 0"
        style="position: absolute; opacity: 0.6; left: 16px; transform: translateY(-50%) scaleY(3)"
        :style="{ top: $device.isMobile ? '130px' : '50%' }"
        width="28"
      />
      <el-icon-arrow-right
        v-if="seasonToCheckRewards === BigNumber(currentSeason!).minus(1).toNumber()"
        style="position: absolute; opacity: 0.6; right: 16px; transform: translateY(-50%) scaleY(3)"
        :style="{ top: $device.isMobile ? '130px' : '50%' }"
        width="28"
      />

      <el-carousel-item v-for="item in BigNumber(currentSeason!).toNumber() || 0" :key="item">
        <div class="carouselItem">
          <div class="statisticWrapper">
            <div :class="{ row: !$device.isMobile }" class="statisticItem">
              <span class="label">{{ $t('placeInSeason') }}:</span>
              <span class="value">
                <img src="/img/season_cup.svg" width="45" height="45" alt="" />
                {{ seasonPosition }}
              </span>
            </div>

            <div :class="{ row: !$device.isMobile }" class="statisticItem">
              <span class="label">{{ $t('seasonPoints') }}:</span>
              <span class="value">
                <img :src="tokens.seasonPoints" width="45" height="45" alt="" />
                {{ userScore?.[1] }}
              </span>
            </div>
          </div>
          <h5 v-if="!$device.isMobile && seasonRewards[0]?.length" class="rewardsHeading">{{ $t('yourRewards') }}</h5>
          <div
            v-if="seasonRewards[0]?.length"
            class="claimBlock"
            :class="{ 'direction-column': seasonRewards.length > 3 || $device.isMobile }"
          >
            <div v-for="reward of seasonRewards[0]" :key="reward[0]" class="rewardTokens">
              <div class="rewardToken">
                <img
                  width="40"
                  :src="tokens[tokenMetaData(blockchain.contracts, reward[0])?.name?.contract as Tokens] as string"
                  alt=""
                />
                {{ !reward[2] ? BigNumber(formatEther(reward[1])).toFormat(2) : BigNumber(reward[1]).toFormat(2) }}
              </div>
            </div>
            <button
              v-if="address"
              class="btn-primary btn-primary_season-design"
              :disabled="isLoadingClaimButton"
              type="button"
              @click="claimReward"
            >
              <span>{{ $t('appStatisticClaim') }}</span>
              &nbsp;<span v-if="confirmationNumberRef && isLoadingClaimButton"
                >{{ confirmationNumberRef }} / {{ blockchain.minConfirmationsCount }} &nbsp;</span
              >

              <img src="/img/icons/chevronRight.svg" alt="chevron right" />
            </button>
          </div>
          <button v-if="$device.isMobile" class="btn-outline small w-button continueBtn" @click="() => emit('close')">
            {{ $t('continue') }}
          </button>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script lang="ts" setup>
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { formatEther } from 'ethers';
import tokens from '~/public/img/tokens';
import { SeasonModalView } from '~/types/season';
import type { Tokens } from '~/utils/constants';
import { BigNumber } from 'bignumber.js';

const { getContract } = useAbiAccess();
const { blockchain } = useEnvs();
const { address } = useWeb3ModalAccount();
const { sendContractMethod } = useSendContractMethod();
const store = useMainStore();
const { t } = useI18n();

const currentSeason = inject<number>('currentSeasonId');
const position = inject<Ref<number, number>>('playerPosition');
const modalView = inject('modalView');
const showSeasonModal = inject('showSeasonModal');

const seasonYearContract = await getContract('SEASON_REWARDS_YEAR_1', blockchain.contracts.SEASON_REWARDS_YEAR_1);
const seasonContract = await getContract('season', blockchain.contracts.season);

const userScore = ref([]);

const seasonToCheckRewards = ref(BigNumber(currentSeason!).minus(1).toNumber());
const seasonRewards = ref<any[][]>([]);
const seasonPosition = ref(position?.value);
const isLoadingClaimButton = ref(false);
const confirmationNumberRef = ref(0);

watch(
  showSeasonModal!,
  (newValue) => {
    if (!newValue) seasonToCheckRewards.value = BigNumber(currentSeason!).minus(1).toNumber();
  },
  { immediate: true }
);

watch(
  [seasonToCheckRewards, address, () => store.notificationUpdateVersion],
  async ([newSeasonToCheckRewards, newAddress, newNotificationsVersion]) => {
    if (BigNumber(newNotificationsVersion).isNaN()) return;
    seasonRewards.value = await seasonYearContract?.getUserSeasonPositionRewards(newSeasonToCheckRewards, newAddress);
    seasonPosition.value = await seasonContract?.getUserPositionInSeason(newSeasonToCheckRewards, newAddress, 1000);
    userScore.value = await seasonContract?.getUserScore(newSeasonToCheckRewards, newAddress);
  },
  { immediate: true }
);

const emit = defineEmits<{
  close: [];
  updateCheckedSeason: [value: number];
}>();

watch(
  seasonToCheckRewards,
  (newSeason) => {
    if (!BigNumber(newSeason).isNaN()) emit('updateCheckedSeason', newSeason);
  },
  { immediate: true }
);

const openHandler = () => {
  emit('close');
};

const onSeasonSlideChange = (newI: number) => {
  seasonToCheckRewards.value = newI;
};

const claimReward = async () => {
  isLoadingClaimButton.value = true;
  try {
    confirmationNumberRef.value = 0;

    const claimTx = await sendContractMethod(
      {
        contract: 'SEASON_REWARDS_YEAR_1',
        address: blockchain.contracts.SEASON_REWARDS_YEAR_1,
        methodName: 'claimSeasonRewards',
        methodArguments: [BigNumber(seasonToCheckRewards.value || 0).toNumber()]
      },
      () => {
        confirmationNumberRef.value = confirmationNumberRef.value + 1;
      }
    );

    if (!claimTx?.hash) return;
    saveHashToLocalStorage(t('seasonRewards'), claimTx.hash);
    store.updateVersion();

    await claimTx?.wait(blockchain.minConfirmationsCount);
    const confirmations = await claimTx?.confirmations();

    if (confirmations >= blockchain.minConfirmationsCount && claimTx?.hash) {
      ElNotification.success({
        title: 'Token was claimed',
        message: ''
      });

      modifyHistoryHashStatus(claimTx.hash, 'Done');
      setTimeout(() => store.updateVersion(), 2000);
    }
  } catch (error) {
    console.error(error, 'error on claim');
    ElNotification.error({
      title: '',
      message: 'Error while claiming crafted token'
    });
  } finally {
    isLoadingClaimButton.value = false;
  }
};
</script>

<style lang="scss" scoped>
.seasonEndContainer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    height: 100%;
  }

  flex-direction: column;
  gap: 25px;

  .statisticWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 67px;
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 10px;
    }

    .statisticItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      font-family: Grenze, sans-serif;
      &.row {
        flex-direction: row;
        gap: 10px;
        .value {
          font-size: 24px;
        }
      }

      .label {
        font-size: 20px;
        color: white;
      }

      .value {
        font-size: 40px;
        color: #eea92e;
        display: flex;
        align-items: center;
      }
    }
  }

  .claimBlock {
    width: 80%;

    @media (max-width: 767px) {
      width: 100%;
    }

    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #1e353e;
    padding: 15px 10px;
    border-radius: 12px;

    &.direction-column {
      gap: 20px;
      flex-direction: column;
      flex-wrap: wrap;
    }

    background: linear-gradient(180deg, #60ff7c 0%, #04202b 100%);
  }

  .rewardTokens {
    display: flex;
    gap: 10px;
    align-items: center;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .rewardToken {
    display: flex;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: 800;
  }

  .carouselItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;

    @media (max-width: 767px) {
      justify-content: flex-start;
    }
  }

  .rewardsHeading {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }

  .btn-primary_season-design {
    padding: 12px 24px;
    display: flex;
    gap: 8px;
    font-size: 18px;
    text-wrap: nowrap;
  }

  .continueBtn {
    width: 80%;
    margin-top: 20px;
  }
}
</style>

<style lang="scss">
.el-dialog.seasonModal {
  .el-carousel {
    width: 100%;

    @media (max-width: 767px) {
      height: 100%;
    }
  }

  .el-carousel__arrow {
    @media (max-width: 767px) {
      top: 130px;
    }

    &:hover {
      background-color: transparent;
    }

    .el-icon {
      width: 100%;
      height: 100%;

      svg {
        transform: scaleX(2) scaleY(3);
      }
    }
    background-color: transparent;
    width: 28px;
    height: 78px;
  }
}
</style>
