<template>
  <div v-for="product of recipe?.products" :key="product.tokenAddress" class="ready-for-claim">
    <figure class="crafting-item-figure">
      <img
        :src="
          recipe?.products[0] &&
          $getIconToken(
            recipe.products[0],
            $isShip(recipe.products[0]) ? getShipIndexByRecipeId(BigNumber(recipe.recipeId).toNumber()) : undefined
          )
        "
        :alt="tokenMetadataComputed?.label"
      />
      <p v-if="!isNft">
        <span v-if="new BigNumber(product.quantityMin).eq(product.quantityMax)">
          {{ new BigNumber(product.quantityMax).multipliedBy(props.activityItem.available) }}
        </span>
        <span v-else>
          {{ new BigNumber(product.quantityMin).decimalPlaces(1).multipliedBy(props.activityItem.available) }}-{{
            new BigNumber(product.quantityMax).decimalPlaces(1).multipliedBy(props.activityItem.available)
          }}
        </span>
      </p>
      <p v-else>
        {{ props.activityItem.available }}
      </p>
    </figure>
    <div class="ready-for-claim-content">
      <button class="btn-primary" type="button" :disabled="disabled" @click="claimCraftedTokens(props.activityItem.id)">
        <span>{{ $t('appStatisticClaim') }}</span>
        &nbsp;<span v-if="confirmationNumberRef && isLoadingClaimButton"
          >{{ confirmationNumberRef }} / {{ blockchain.minConfirmationsCount }} &nbsp;</span
        >

        <img src="/img/icons/chevronRight.svg" alt="chevron right" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, inject, computed } from 'vue';
import { ElNotification } from 'element-plus';
import { BigNumber } from 'bignumber.js';

import type { RecipeProduct, RecipeProps, UserActivityAvailableForClaim } from '~/types/crafting';
import { useI18n } from '#imports';
import { type TTokensConfigItem } from '~/utils/constants';
import useEnvs from '~/composables/useEnvs';
import { useMainStore } from '~/stores/mainStore';
import { modifyHistoryHashStatus, saveHashToLocalStorage } from '~/utils';
import useSendContractMethod from '~/composables/useSendContractMethod';
import { AbiCoder, formatEther } from 'ethers';
import { capitalizeFirstLetter } from '~/utils/formatters';
import type { TNullable } from '~/types/common';

const { sendContractMethod } = useSendContractMethod();
const refreshListInjected = inject<() => void>('refreshList');

const { blockchain } = useEnvs();
const store = useMainStore();

const confirmationNumberRef = ref<number>(0);
const isLoadingClaimButton = ref<boolean>(false);
const { t } = useI18n();

const props = defineProps<{
  selectedRecipeId?: string;
  tokenMetadataComputed: TNullable<TTokensConfigItem>;
  activityItem: UserActivityAvailableForClaim;
  recipe?: RecipeProps;
  disabled: boolean;
}>();

const emit = defineEmits<{ claimLoadStateChanged: [isLoading: boolean] }>();

watch(isLoadingClaimButton, (newIsLoadingClaim) => emit('claimLoadStateChanged', newIsLoadingClaim));

const isNft = computed(() => {
  if (!props.recipe) return false;

  return (
    props.recipe &&
    props.recipe.products.every(
      (product: RecipeProduct) => product.nftValueMin !== null && product.nftValueMax !== null
    )
  );
});

const tokenUniqueness = computed(() => {
  if (!props.recipe) return '';

  const product = props.recipe.products[0];
  const value = getNftValueByAddress(blockchain.contracts, product);

  return value;
});

// const getIconWrapper = () => {
//   return props.recipe?.products[0] && getIconToken(props.recipe.products[0]);
// };

const claimCraftedTokens = async (id: number) => {
  isLoadingClaimButton.value = true;

  try {
    const claimTx = await sendContractMethod(
      {
        contract: 'crafting',
        address: blockchain.contracts.crafting,
        methodName: 'claimRecipe',
        methodArguments: [props.selectedRecipeId, id]
      },
      () => {
        confirmationNumberRef.value = confirmationNumberRef.value + 1;
      }
    );

    if (!claimTx?.hash) return;
    const txReceipt = await claimTx?.wait();

    if (txReceipt) {
      const abiCoder = new AbiCoder();
      const amount = formatEther(abiCoder.decode(['uint'], txReceipt.logs[0].data)[0]);

      let description: string = '';

      if (isNft.value && props.recipe) {
        const methodName = tokenMetaData(blockchain.contracts, props.recipe.products[0].tokenAddress)?.methodName;
        description = `${t(methodName || '')} ${capitalizeFirstLetter(tokenUniqueness.value)}`;
      } else if (props.recipe) {
        const methodName = tokenMetaData(blockchain.contracts, props.recipe.products[0].tokenAddress)?.methodName;

        description = `(${BigNumber(amount).decimalPlaces(2)} ${methodName.toUpperCase()})`;
      }

      saveHashToLocalStorage(`${t('dashboardPurchasesClaimed')} ${description}`, claimTx.hash);
    }

    isLoadingClaimButton.value = false;
    store.updateVersion();

    await claimTx?.wait(blockchain.minConfirmationsCount);

    const confirmations = await claimTx?.confirmations();

    if (confirmations >= blockchain.minConfirmationsCount && claimTx?.hash) {
      modifyHistoryHashStatus(claimTx.hash, 'Done');

      store.updateVersion();
      ElNotification.success({
        title: 'Token was claimed',
        message: ''
      });
      confirmationNumberRef.value = 0;

      // refresh after success for optimistic UI
      refreshListInjected && refreshListInjected();
    }
  } catch (error) {
    console.error(error, 'error on claim');
    isLoadingClaimButton.value = false;

    ElNotification.error({
      title: '',
      message: 'Error while claiming crafted token'
    });
  }
};
</script>

<style scoped lang="scss">
.ready-for-claim {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  padding: 15px 10px;
  border-radius: 12px;
  width: 350px;
  max-height: 75px;
  border: 2px solid #1e353e;

  background: linear-gradient(180deg, #60ff7c 0%, #04202b 100%);

  button {
    margin-left: 20px;
    height: 40px;
    line-height: 27px;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 7px;

    svg {
      margin-left: 8px;
      path {
        transition: all 0.3s;
      }
    }

    &:hover {
      svg {
        path {
          fill: #fff !important;
        }
      }
    }
  }

  &-content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.crafting-item-figure {
  max-width: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  margin: 0;
  font-family: Eczar, sans-serif;

  p {
    margin-bottom: 0;
    margin-left: 5px;
    font-weight: 800;
    font-size: 24px;
    color: #fff;
    text-wrap: nowrap;
  }

  img {
    width: 100%;
  }
}
</style>
