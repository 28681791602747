<template>
  <div class="token">
    <h4 class="tokenTitle">
      <div class="imgContainer">
        <img :src="props.nftToken.imageUrl" :alt="props.nftToken.name" />
      </div>
      <span>{{ $t(getTranslationKey(props.nftToken.name) || '') }}</span>
    </h4>
    <div class="tokenDescription">
      <p>{{ $t(props.nftToken.description || '') }}</p>
      <p class="amount">1</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import type { TokenDescription } from '~/types/token';
import { getTranslationKey } from './translationHelper';

const props = defineProps<{
  nftToken: TokenDescription;
}>();
</script>

<style lang="scss" scoped>
.token {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
  border-radius: 15px;
  padding: 10px;
  border: 1px solid #2f2f2f;
  background: linear-gradient(0deg, #041a27 0%, #041a27 100%), #14232a;
}

.tokenTitle {
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  width: 110px;
  line-height: 1;

  @media screen and (max-width: 560px) {
    span {
      font-size: 13px;
    }
  }

  .imgContainer {
    width: 30px;
    height: 45px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 479px) {
    font-size: 24px;
  }
}

.tokenDescription {
  gap: 10px;
  font-size: 12px;
  color: #8e979c;
  width: calc(100% - 85px);

  .amount {
    color: #fff;
    font-size: 16px;
    margin-top: 16px;
    font-weight: 900;
  }
}
</style>
