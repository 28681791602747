<template>
  <el-badge :offset="[-3, 3]" :is-dot="(badgeValue || 0) > 0" class="item">
    <button
      :class="[props.mode === 'full' ? 'building-btn' : 'square-btn']"
      size="large"
      :disabled="disabled"
      @click="() => emit('handleClick')"
    >
      <div class="container">
        <img :src="`/img/icons/${props.icon}.svg`" alt="icon" />
        <span v-if="props.mode === 'full'">{{ props.text }}</span>
      </div>
    </button>
  </el-badge>
</template>

<script setup lang="ts">
const props = defineProps<{
  icon: string;
  mode: string;
  text?: string;
  badgeValue?: number;
  disabled?: boolean;
}>();

const emit = defineEmits(['handleClick']);
</script>

<style lang="scss" scoped>
button {
  &:disabled {
    .container {
      opacity: 0.3;
    }
  }
}
.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.building-btn {
  background: linear-gradient(to bottom, #0083ad, #00354f);
  width: 170px;
  border: 1px solid #3b7285;
  color: white;
  transition: width 0.5s;

  img {
    width: 27px;
    height: 27px;
  }

  span {
    padding-left: 6px;
    font-size: 18px;
  }
}

.square-btn {
  background: linear-gradient(to bottom, #040f14, #081b25);
  width: 48px;
  border: 1px solid #016b79;
  transition: width 0.5s;
}

.building-btn,
.square-btn {
  overflow: hidden;
  border-radius: 12px;
  height: 48px;
}
</style>
