<template>
  <div class="seasonModalSwitcherContainer">
    <div
      v-for="option of SEASON_SWITCHER_MODAL_OPTIONS"
      :key="option.name"
      class="option"
      :class="{ active: $props.modalView === option.name }"
      @click="emit('updateModalView', option.name)"
    >
      <div class="option-content">
        <img :width="$device.isMobile ? 30 : 57" :src="option.iconSrc" alt="" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { SeasonModalView } from '~/types/season';
import { SEASON_SWITCHER_MODAL_OPTIONS } from '~/utils/constants';

const emit = defineEmits<{ updateModalView: [modalView: SeasonModalView] }>();
defineProps<{ modalView: SeasonModalView }>();
</script>
<style lang="scss" scoped>
.seasonModalSwitcherContainer {
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  @media screen and (max-width: 560px) {
    position: static;
    transform: translateX(-16px);
    flex-direction: row;
    align-items: stretch;
    width: 100vw;
    border-top: 1px solid #1e353e;
    border-bottom: 1px solid #1e353e;
    min-height: 44px;
    gap: 0;
    margin-top: 20px;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px 0;
    border: 1px solid #1e353e;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background: linear-gradient(to right, #425b6a 0%, #0e1b25 100%);
    cursor: pointer;

    &.active {
      background: linear-gradient(to right, #0083ad 0%, #0e1b25 100%);
      cursor: auto;
    }

    @media screen and (max-width: 560px) {
      width: 100%;
      padding: 0 10px;
      background: none;
      border-left-width: 1px;
      border-right-width: 1px;
      border-top-width: 0.5px;
      border-bottom-width: 0.5px;

      border-radius: 0;

      &:first-child,
      &:last-child {
        border: none;
      }

      &.active {
        background: linear-gradient(to bottom, #0083ad 0%, #00354f 100%);
        cursor: auto;
      }
    }
  }

  .option-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .switcherOptionLabel {
    font-size: 20px;
    font-family: Grenze, sans-serif;
    font-weight: 400;
    color: #fff;
  }
}
</style>
