import { useRuntimeConfig } from 'nuxt/app';
import tokens, { type ComplexToken } from '~/public/img/tokens';
import { tokenMetaData } from '~/utils';
import { reactive } from 'vue';
import type { Tokens } from '~/utils/constants';

const loadedTokensImages = reactive<Record<'mainnet' | 'testnet', ComplexToken | null>>({
  mainnet: null,
  testnet: null
});

const preloadComplexTokensImages = async () => {
  const config = useRuntimeConfig();

  if (config?.public.env === 'prod') {
    loadedTokensImages.mainnet = (await import('~/public/img/tokens')).complexTokensMainnet;
  } else {
    loadedTokensImages.testnet = (await import('~/public/img/tokens')).complexTokensTestnet;
  }
};

const getIcon = (recipeId: number, tokenAddress: string): string => {
  const { blockchain } = useEnvs();
  const config = useRuntimeConfig();
  const tokensImages = config?.public.env === 'prod' ? loadedTokensImages.mainnet : loadedTokensImages.testnet;

  if (!tokensImages) {
    throw new Error('Tokens images not loaded yet.');
  }

  if (recipeId in tokensImages) {
    return tokensImages[recipeId].toString();
  } else {
    const tokenData = tokenMetaData(blockchain.contracts, tokenAddress);

    return tokens?.[tokenData?.name?.contract as Tokens]?.toString();
  }
};

export { preloadComplexTokensImages, getIcon };
