export function calculateTimeLeftPercent(startTime: number, endTime: number, currentTime: number): number {
  const totalTime = endTime - startTime;
  const timePassed = currentTime - startTime;

  if (totalTime <= 0 || timePassed <= 0) {
    return 0;
  }

  return (timePassed / totalTime) * 100;
}
