export const ERRORS_CONFIG = {
  apiErrors: {
    emailSubscription: {
      frequentRequest: {
        title: 'Too frequent request',
        message: 'Please, try again 2 minutes later'
      }
    },
    claimError: {
      generic: {
        title: '',
        message: 'Error while claiming crafted token'
      }
    }
  },
  blockchainErrors: {
    transferAmountExceedBalance: 'execution reverted: "ERC20: transfer amount exceeds balance"'
  }
};
